<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12">
                <v-form class="custom-form" @submit.prevent="bankStatements">
                    <v-card :elevation="1" color="grey lighten-3">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-card-text class="py-0">
                                    <v-row dense>
                                        <v-col cols="5">
                                            <v-row no-gutters>
                                                <v-col cols="2">Form Date</v-col>
                                                <v-col cols="4">
                                                    <v-menu>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                v-model="filter.dateFrom"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                            <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                                            </v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="filter.dateFrom"></v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                                <v-col cols="2" class="text-center">To Date</v-col>
                                                <v-col cols="4">
                                                    <v-menu>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                v-model="filter.dateTo"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                            <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                                            </v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="filter.dateTo"></v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="1">
                                            <v-btn height="26px" type="submit" dark block color="light-blue darken-2" :loading="isLoading">Search</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12">
                <v-row dense>
                    <v-col cols="4">
                        <v-card elevation="2">
                            <div class="account-item">
                                <v-row no-gutters>
                                    <v-col cols="4">
                                        <div class="icon">
                                            <v-icon size="35">mdi-cash-plus</v-icon>
                                            <div class="ac-title">Cash In</div>
                                        </div>
                                    </v-col>
                                    <v-col cols="8">
                                        <div class="amount">Tk. {{ cashInTotal }}</div>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="4">
                        <v-card elevation="2">
                            <div class="account-item">
                                <v-row no-gutters>
                                    <v-col cols="4">
                                        <div class="icon">
                                            <v-icon size="35">mdi-cash-minus</v-icon>
                                            <div class="ac-title">Cash Out</div>
                                        </div>
                                    </v-col>
                                    <v-col cols="8">
                                        <div class="amount">Tk. {{ cashOutTotal }}</div>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="4">
                        <v-card elevation="2">
                            <div class="account-item">
                                <v-row no-gutters>
                                    <v-col cols="4">
                                        <div class="icon">
                                            <v-icon size="35">mdi-cash-100</v-icon>
                                            <div class="ac-title">Balance</div>
                                        </div>
                                    </v-col>
                                    <v-col cols="8">
                                        <div class="content">
                                            <div class="amount">Tk. {{ cashInTotal - cashOutTotal }}</div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="6">
                <v-row no-gutters class="mb-2">
                    <v-col cols="12">
                        <v-simple-table dense>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th colspan="3">Initial Balance</th>
                                    </tr>
                                    <tr>
                                        <th>SL</th>
                                        <th>Bank Name</th>
                                        <th>Initial Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(bank, ind) in $store.getters['bankTransaction/bankStatement'].accounts" :key="ind">
                                        <td>{{ ind + 1 }}</td>
                                        <td>{{ bank.bank_name }}</td>
                                        <td class="text-right">{{ bank.initial_balance }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" class="text-right font-weight-bold">Total</td>
                                        <td class="text-right"><strong>{{ initialTotal }}</strong></td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
                <v-row no-gutters class="mb-2">
                    <v-col cols="12">
                        <v-simple-table dense>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th colspan="5">Received from Patients</th>
                                    </tr>
                                    <tr>
                                        <th>SL</th>
                                        <th>Date</th>
                                        <th>Code</th>
                                        <th>Patient</th>
                                        <th>Received</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(patient, ind) in $store.getters['bankTransaction/bankStatement'].patients" :key="ind">
                                        <td>{{ ind + 1 }}</td>
                                        <td>{{ patient.payment_date }}</td>
                                        <td>{{ patient.code }}</td>
                                        <td>{{ patient.name }}</td>
                                        <td class="text-right">{{ patient.amount }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="4" class="text-right font-weight-bold">Total</td>
                                        <td class="text-right"><strong>{{ patientTotal }}</strong></td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
                <v-row no-gutters class="mb-2">
                    <v-col cols="12">
                        <v-simple-table dense>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th colspan="6">Bank Deposits</th>
                                    </tr>
                                    <tr>
                                        <th>Sl</th>
                                        <th>Account Name</th>
                                        <th>Account Number</th>
                                        <th>Bank Name</th>
                                        <th>Date</th>
                                        <th>Deposit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(deposit, ind) in $store.getters['bankTransaction/bankStatement'].bankDeposit" :key="ind">
                                        <td>{{ ind + 1 }}</td>
                                        <td>{{ deposit.account_name }}</td>
                                        <td>{{ deposit.account_number }}</td>
                                        <td>{{ deposit.bank_name }}</td>
                                        <td>{{ deposit.transaction_date }}</td>
                                        <td class="text-right">{{ deposit.amount }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="5" class="text-right font-weight-bold">Total</td>
                                        <td class="text-right"><strong>{{ depositTotal }}</strong></td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="6">
                <v-row no-gutters class="mb-2">
                    <v-col cols="12">
                        <v-simple-table dense>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th colspan="6">Bank Withdraws</th>
                                    </tr>
                                    <tr>
                                        <th>Sl</th>
                                        <th>Account Name</th>
                                        <th>Account Number</th>
                                        <th>Bank Name</th>
                                        <th>Date</th>
                                        <th>Withdraw</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(withdraw, ind) in $store.getters['bankTransaction/bankStatement'].bankWithdraw" :key="ind">
                                        <td>{{ ind + 1 }}</td>
                                        <td>{{ withdraw.account_name }}</td>
                                        <td>{{ withdraw.account_number }}</td>
                                        <td>{{ withdraw.bank_name }}</td>
                                        <td>{{ withdraw.transaction_date }}</td>
                                        <td class="text-right">{{ withdraw.amount }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="5" class="text-right font-weight-bold">Total</td>
                                        <td class="text-right"><strong>{{ withdrawTotal}}</strong></td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
                <v-row no-gutters class="mb-2">
                    <v-col cols="12">
                        <v-simple-table dense>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th colspan="4">Suppliers Paid</th>
                                    </tr>
                                    <tr>
                                        <th>SL</th>
                                        <th>Code</th>
                                        <th>Suppliers</th>
                                        <th>Received</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(supplier, ind) in $store.getters['bankTransaction/bankStatement'].suppliers" :key="ind">
                                        <td>{{ ind + 1 }}</td>
                                        <td>{{ supplier.code }}</td>
                                        <td>{{ supplier.name }}</td>
                                        <td class="text-right">{{ supplier.payment_amount }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="3" class="text-right font-weight-bold">Total</td>
                                        <td class="text-right"><strong>{{ supplierTotal }}</strong></td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
                
                <v-row no-gutters class="mb-2">
                    <v-col cols="12">
                        <v-simple-table dense>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th colspan="5">Commision Payments</th>
                                    </tr>
                                    <tr>
                                        <th>Sl</th>
                                        <th>Trnx. Id</th>
                                        <th>Date</th>
                                        <th>Pharmacis</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(commission, ind) in $store.getters['bankTransaction/bankStatement'].commissionPayment" :key="ind">
                                        <td>{{ ind + 1 }}</td>
                                        <td>{{ commission.code }}</td>
                                        <td>{{ commission.date}}</td>
                                        <td>{{ commission.pharmacist }}</td>
                                        <td class="text-right">{{ commission.amount }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="4" class="text-right font-weight-bold">Total</td>
                                        <td class="text-right"><strong>{{ commissionTotal }}</strong></td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

export default {
    name: 'bank-statement',
    data: ()=> ({
        filter: {
            dateFrom: new Date().toISOString().substr(0, 10),
            dateTo: new Date().toISOString().substr(0, 10),
        },
        isLoading: false
    }),

    computed: {
        initialTotal() {
           return this.$store.getters['bankTransaction/bankStatement'].accounts.reduce((p, c) => { return +p + +c.initial_balance }, 0);
        },

        patientTotal() {
           return this.$store.getters['bankTransaction/bankStatement'].patients.reduce((p, c) => { return +p + +c.amount }, 0);
        },

        supplierTotal() {
            return this.$store.getters['bankTransaction/bankStatement'].suppliers.reduce((p, c) => { return +p + +c.payment_amount }, 0);
        },

        withdrawTotal() {
            return this.$store.getters['bankTransaction/bankStatement'].bankWithdraw.reduce((p, c) => { return +p + +c.amount }, 0);
        },

        depositTotal() {
            return this.$store.getters['bankTransaction/bankStatement'].bankDeposit.reduce((p, c) => { return +p + +c.amount }, 0);
        },

        commissionTotal() {
            return this.$store.getters['bankTransaction/bankStatement'].commissionPayment.reduce((p, c) => { return +p + +c.amount }, 0);
        },

        cashInTotal() {
            return (parseFloat(this.initialTotal) + parseFloat(this.patientTotal) + parseFloat(this.depositTotal));
        },

        cashOutTotal() {
            return (parseFloat(this.withdrawTotal) + parseFloat(this.commissionTotal) + parseFloat(this.supplierTotal))
        }
    },

    methods: {
        bankStatements() {
            this.isLoading = true;
            this.$store.dispatch('bankTransaction/getBankStatements', this.filter)
            this.isLoading = false;
        }
    }
}
</script>

<style lang="scss" scoped>
    .v-icon.v-icon {
       font-size: 18px;
       top: -3px;
    }
   .account-item {
        text-align: center;
        font-size: 13px;
        color: black;
        line-height: 18px;
        background: rgba(66, 84, 94, 0.096);
        .icon {
            background: rgba(66, 84, 94, 0.945);
            height: 100%;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            padding: 10px 0;
            .ac-title {
                color: #ffffff;
                font-size: 20px;
            }
            .v-icon {
                color: #ffffff;
                top: 0;
            }
        }
        .amount {
            font-size: 20px;
            padding: 27px 0 0;
        }
    }
    .theme--light.v-data-table {
        thead {
            tr {
                th {
                    border: 1px solid rgba(204, 204, 204, 0.253);
                    text-align: center !important;
                    font-size: 14px;
                    padding: 0px 1px;
                    height: 24px;
                    letter-spacing: .7px;
                    background: rgba(66, 84, 94, 0.096);
                    color: #000 !important;
                }
            }
            tr:last-child {
                th {
                    font-size: 12px;
                    background: rgba(66, 84, 94, 0.329);
                }
            }
        }
    }
   .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td {
        height: 20px !important;
        padding: 1px 5px !important;
        font-size: 12px !important;
        border: 1PX solid #ccc !important;
        text-align: center;
   }
</style>